<template>
  <div class="h-100 d-flex">
    <div class="overflow-scroll w-100">
      <div class="d-flex flex-column h-100 w-100 p-1">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <b-button variant="outline-secondary" class="size-button-center" @click="$emit('slide_window')">
             <span><feather-icon icon="MenuIcon"/></span>
          </b-button>
          <div class="d-flex">
            <b-button 
              variant="outline-secondary" 
              class="size-button-center" 
              v-if="Object.keys(video_selected).length > 0"
              @click="downloadFile(video_selected.result_url)"
            >
              <span><feather-icon icon="DownloadIcon"/></span>
            </b-button>
            <b-button 
              variant="outline-secondary" 
              class="size-button-center ml-1 icon-setting-sider d-xl-none"
              @click="sider_option_ai_responsive = !sider_option_ai_responsive"
              v-if="is_not_free !== 'free' || Object.keys(video_selected).length > 0"
            >
              <b-icon icon="gear-wide-connected" font-scale="1.3" variant="dark"></b-icon>
            </b-button>
          </div>
        </div>
        <div v-if="is_not_free === 'free' && Object.keys(video_selected).length === 0" class="d-flex flex-column hvh-100">
          <div class="flex-grow d-flex flex-column">
            <h5>{{ $t('ia.video.whatisit') }}</h5>
            <p class="avenir-medium">{{ $t('ia.video.whatisitText') }}:</p>
            <div class="container-video position-relative">
              <div class="position-relative d-flex align-items-center">
                <video class="video-media-default" controls :src="require('@/assets/videos/talking-portrait-default.mp4')"></video>
              </div>
            </div>
          </div>
          <div class="background-input-botton">
            <div class="d-flex justify-content-between align-items-center div-input-button">
              <p class="size-input avenir-medium m-0">{{ $t('ia.video.updateToCreate') }}</p>
              <b-button class="generate-img-ai" @click="$emit('show_modal_update_suscription', {title: $t('ia.video.updateTitleModal'), subtitle: $t('ia.video.updateDescriptionModal')})">{{ $t('lists.update') }}</b-button>
            </div>
          </div>
        </div>
        <!-- Container videos made it -->
        <div v-else-if="Object.keys(video_selected).length === 0 && script.presenter_uuid === null" class="position-relative flex-grow">
          <h5 v-if="videos.length > 0">{{ $t('ia.video.recentlyVideos')}}</h5>
          <b-row :class="`grid-container m-0`" v-if="videos.length > 0">
            <div v-for="(video, index) in videos" :key="index" class="cursor-pointer" @click="selectVideo(video)">
              <div class="container-aspect-ratio">
                <video :src="video.result_url" class="img-ia" v-if="video.result_url !== null"></video>
                <b-skeleton class="img-ia skeleton" v-else></b-skeleton>

                <div class="container-dropdown-image" @click="downloadFile(video.result_url)">
                  <feather-icon icon="DownloadIcon" class="icon-download"></feather-icon>
                </div>
              </div>
              <div>
                <strong>{{ formatDateIA(new Date(video.created_at)) }}</strong>
              </div>
            </div>
          </b-row>
          <div v-else-if="loaded_videos" class="d-flex flex-column">
            <h5>{{ $t('ia.video.whatisit') }}</h5>
            <p class="avenir-medium">{{ $t('ia.video.whatisitText') }}:</p>
            <div class="container-video position-relative">
              <div class="position-relative d-flex align-items-center">
                <video class="video-media-default" controls :src="require('@/assets/videos/talking-portrait-default.mp4')"></video>
              </div>
            </div>
          </div>
        </div>
        <!-- --------------------------- -->
        <!-- Container choose aspect ratio, for preseneter -->
        <div class="flex-grow" v-if="script.presenter_uuid !== null">
          <div class="h-100 container-video-image-choosed">
            <b-img :src="presenters.find((presenter) => presenter.uuid === script.presenter_uuid).url" class="image-presenter-choosed"></b-img>
            <p class="avenir-medium text-center mt-2">ASPECT RATIO (1:1)</p>
            <b-form-input disabled id="range-2" v-model="value_range_aspect" type="range" min="0" max="6" step="1" class="px-5"></b-form-input>
          </div>
        </div>
        <!-- --------------------------- -->
        <!--  Container Video selected -->
        <div v-else-if="Object.keys(video_selected).length > 0" class="flex-grow">
          <div class="">
            <video class="video-media-default" controls :src="video_selected.result_url"></video>
          </div>
        </div>
        <!-- --------------------------- -->
        
        <!-- Input buttom to GENERATE VIDEO -->
        <div class="background-input-botton" v-if="Object.keys(video_selected).length === 0 && is_not_free !== 'free'">
          <div class="d-flex justify-content-between align-items-center div-input-button">
            <p class="size-input avenir-medium m-0">
              {{ script.text_script && script.text_script.length ? $t('ia.video.readyGenerate') : script.presenter_uuid !== null ?  $t('ia.video.videoSelected') : $t('ia.video.selectVideo') }} 
            </p>
            <b-button 
              v-if="isAvaibleToGenerateVideo() && !loading_service" 
              class="generate-img-ai d-flex align-items-center"
              @click="generateVideo()"
            >
              {{$t('search.generateImg')}}
              <b-badge class="badge-button-ia"><feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon>{{ getCredits() >= 0 ? getCredits() : $t('ia.general.insufficientCredits') }}</b-badge>
            </b-button>
            <b-skeleton v-else-if="loading_service" class="generate-img-ai" type="button"></b-skeleton>
            <b-button v-else class="generate-img-ai">
              {{ script.presenter_uuid !== null ? $t('ia.video.enterScript') : $t('ia.video.getStarted') }}
            </b-button>
          </div>
        </div>
        <!-- ------------------------- -->
        <!-- Controls back, forward, backward when a video is selected -->
        <div v-else-if="Object.keys(video_selected).length > 0" class="d-flex justify-content-between border-top pt-2">
          <div>
            <b-button variant="outline-secondary" @click="clearVideoSelected">Back</b-button>
          </div>
          <div>
            <b-button variant="outline-secondary" @click="changeVideoSelected('backward')">
              <feather-icon icon="ChevronLeftIcon"></feather-icon>
            </b-button>
            <b-button variant="outline-secondary" class="ml-1" @click="changeVideoSelected('forward')">
              <feather-icon icon="ChevronRightIcon"></feather-icon>
            </b-button>
          </div>
        </div>
         <!-- ------------------------- -->
      </div>
    </div>
    <div class="width-right-area d-none d-xl-block" v-if="is_not_free !== 'free' || Object.keys(video_selected).length > 0">
      <sider-video-actions 
        :script="script" 
        :presenters="presenters"
        :video_selected="video_selected"
        @delete_presenter="deletePresenter"
        @clean_video_selected="clearVideoSelected"
        @clear_script="clearScript"
        ref="sider-video-actions"
      ></sider-video-actions>
    </div>

    <!-- Responsive right area options -->
    <b-sidebar v-model="sider_option_ai_responsive" backdrop shadow right class="sidebar-right-options-responsive-video">
      <sider-video-actions 
        :script="script" 
        :presenters="presenters"
        :video_selected="video_selected"
        @delete_presenter="deletePresenter"
        @clean_video_selected="clearVideoSelected"
        @clear_script="clearScript"
        ref="sider-video-actions"
        v-if="sider_option_ai_responsive"
      ></sider-video-actions>
    </b-sidebar>
    <!--  -->
  </div>
</template>
    
<script>

import {
  BButton,
  BSkeleton,
  BBadge,
  BFormInput,
  BImg,
  BRow,
  BSidebar,
} from 'bootstrap-vue';
import { getUserData } from '@/libs/utils/user'
import { getVideoTalks, getPresenters, createTalk } from '@/services/ia'
import { formatDateIA } from '@/libs/utils/times';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'videoGrid',
  components: {
    BButton,
    BImg,
    BFormInput,
    BBadge,
    BSkeleton,
    BRow,
    BSidebar,
    siderVideoActions: () => import('./siderbars/siderVideoActions.vue'),
  },
  data() {
    return {
      formatDateIA,
      script: {
        presenter_uuid: null,
        text_script: null,
        language: null,
        speaker: null,
        style: null,
      },
      presenters: [],
      value_range_aspect:3,
      aspect_ratio: '1 / 1',
      loading_service: false,
      videos: [],
      video_selected: {},
      sider_option_ai_responsive: false,
      loaded_videos: false,
    }
  },
  props: {
    category_selected: {
      type: Object,
      default: () => {
        return {}
      },
    },
    credits_avaible: {
      type: Number,
      default: 0
    }
  },
  computed: {
    is_not_free() {
      return getUserData().current_plan.name.toLowerCase()
    }
  },
  created() {
    this.getVideoTalks();
    this.getAllPresenters();

    this.$root.$on('video_talk_created_ws', (data) => {
      const index = this.videos.findIndex(i => i.uuid === data.body.uuid)
      this.videos[index].result_url = data.body.result_url;
      this.loader_image_search = false;
    });
  },
  methods: {
    getCredits() {
      return this.credits_avaible >= 150 ? 150 : -1;
    },
    isAvaibleToGenerateVideo() {
      return this.script.text_script && this.script.text_script.length > 0 && this.script.text_script.length <= 3000 && this.script.language && this.script.presenter_uuid
    },
    clearScript() {
      this.script.presenter_uuid = null;
      this.script.text_script = null
    },
    clearVideoSelected() {
      this.video_selected = {}; 
      this.$router.push({ name: 'brandme_AI', params: { section: 'video', category: 'talking-portrait'}}).catch(() => {})
    },
    changeVideoSelected(direction) {
      const old_index = this.videos.findIndex(obj => obj.uuid === this.video_selected.uuid);
      if (direction === 'forward' && old_index < this.videos.length - 1) this.selectVideo(this.videos[old_index + 1])
      else if (direction === 'backward' && old_index > 0) this.selectVideo(this.videos[old_index - 1])
    },
    deletePresenter(presenter_uuid) {
      this.presenters = this.presenters.filter((i) => i.uuid !== presenter_uuid);
    },
    getVideoTalks() {
      getVideoTalks('').then((response) => {
        this.videos = response.results;
        this.loaded_videos = true;
        const uuid_param = this.$route.params.uuid;
        if (uuid_param) {
          this.selectVideo(this.videos.find(video => video.uuid === uuid_param));
        }
      })
    },
    selectVideo(video) {
      this.video_selected = video;
      this.$router.push({ name: 'brandme_AI', params: { section: 'video', category: 'talking-portrait', uuid: video.uuid}}).catch(() => {})
    },
    downloadFile(url) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const splitted = url.split('/');
          link.download = splitted[splitted.length - 1];
          link.click();
        }
      };
      xhr.onerror = () => {
        if (xhr.status === 0)
          window.open(url, '_blank');
      };
      xhr.send();
    },
    getAllPresenters() {
      getPresenters().then((response) => {
        this.presenters = response.results;
      })
    },
    generateVideo() {
      const form_data = new FormData();
      form_data.append('presenter_uuid', this.script.presenter_uuid);
      form_data.append('text_script', this.script.text_script);
      form_data.append('voice_id', this.script.speaker.rest.id);
      form_data.append('voice_name', this.script.speaker.rest.name);
      form_data.append('language', this.script.language.title);
      form_data.append('provider_voice', this.script.speaker.rest.provider);
      
      if (this.script.style) {
        form_data.append('style', this.script.style);
      }

      this.loading_service = true;
      createTalk(form_data).then((response) => {
        if (response.status >= 400) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: 'Ups hubo un error',
                icon: "AlertTriangleIcon",
                text: response.data.response.message,
                variant: 'danger'
              },
            });
          });
        } else {
          this.$emit('update_credits', 150);
          this.$refs['sider-video-actions'].startOverAction();
          this.videos.unshift(response); 
        }
        this.loading_service = false;
      })
    }
  },
  watch: {
    value_range_aspect(val) {

      switch (parseInt(val)) {
      case 0:
        this.aspect_ratio = '16 / 9'
        break;
      case 1: 
        this.aspect_ratio = '5 / 4'
        break;
      case 2: 
        this.aspect_ratio = '4 / 3'
        break;
      case 3: 
        this.aspect_ratio = '1 / 1'
        break;
      default:
        break;
      }

      this.update_image = !this.update_image;
    }
  }
}
</script>
<style lang="scss" scoped>
.width-right-area {
  width: 420px;
  flex-shrink: 0;
}
.grid-container {
  display: grid;
  grid-gap: 14px;
  grid-auto-flow: dense;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);

  @media(max-width: 300px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media(max-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.container-aspect-ratio {

  &:hover {
    .container-dropdown-image {
      transform: translateX(0px);
      opacity: 1;
    }
  }
}
.img-ia {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
.container-dropdown-image {
  background-color: rgba(0, 0, 0, 0.699);
  position: absolute;
  top: 5%;
  border-radius: 0.5em;
  right: 5%;
  color: white;
  transform: translateX(50px);
  padding: 0.5em;
  transition: all 500ms;
  opacity: 0;
  .icon-download {
    width: 18px;
    height: 18px;
  }
}
  .aspect-ratio {
    aspect-ratio: 1 / 1;
    background: linear-gradient(0deg, rgba(250,250,250,1) 0%, rgba(219,219,219,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    
    .skeleton-loading-ia {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
    }

    .container-ia-svg {
      position: absolute;
      width: 5%;
      height: 5%;
      right: 5%;
      background-color: white;
      bottom: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      .ia-svg-icon {
        width: 170%;
        height: 170%;
      }
    }
}
.hvh-100 { 
  height: 100vh;
}
.overflow-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  .flex-grow {
    flex-grow: 1;
    // overflow-y: scroll;
    overflow-x: hidden;

    .container-video {
      flex-grow: 1;
      background-color: #F8F9FA;
      position: relative;
      width: 100%;
      // height: 100%;
      display: flex;
      justify-content: center;
      padding: 1em;

      .container-ia-svg {
        position: absolute;
        width: 5%;
        height: 5%;
        left: 7%;
        background-color: white;
        top: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        .ia-svg-icon {
          width: 300%;
          height: 300%;
        }
      }
    }
  }
  .mr-05 {
    margin-right: 0.5em;
  }
  
  .background-input-botton {
    width: 100%;    
    padding: 1em 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .small-text {
      background-color: #eeeeee;
      padding: 0.2em 0.5em;
      font-family: 'avenir-light';
      border-radius: 1em;
      margin: 0.4em 0;
    }
    .div-input-button {
      width: 100%;
      backdrop-filter: blur(2px);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      padding: 0.5em;
      .send-icon {
        height: 20px;
        width: 20px;
        margin-right: 0.5em;
      }
      .badge-button-ia {
        display: flex;
        align-items: center;
        margin-left: 0.5em;
        line-height: 10px;
        background-color: #40736E;
      .icon-ia {
          width: 14px;
          height: 14px;
          margin-right: 0.2em;
        }
      }
      .generate-img-ai{
        height: 42px;
        display: flex;
        align-items: center;
        background: linear-gradient(to left bottom, rgb(124, 214, 175), rgb(62, 123, 160));
      }
      .size-input{
        background-color: transparent;
        color: #70747c;
        height: 100%;
        width: 80%;
        font-size: 15px;
        display: flex;
        align-items: center;
        border: none;
        box-shadow: none !important;
        font-family: monospace !important;
        font-weight: bold;

        @media(max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }
  .size-button-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 3rem;
  }
}
.container-video-image-choosed {
  background-color: #F8F9FA;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .image-presenter-choosed {
    max-width: 50%;
    margin: 0 auto;
    height: 400px;
    vertical-align: middle;
    object-fit: cover;
  }
}
</style>
<style lang="scss">

.video-media-default {
    // width: 100%;
    // min-height: 100%;
    width: 100%;
    height: 350px;
    
    @media(min-width: 1200px) {
      // flex-grow: 1;
      // height: 100%;
    }
  }
.sidebar-right-options-responsive-video .b-sidebar {
  width: 400px;
}
.dropdown-options-image .btn {
  padding: 0.5em !important;
}
</style>
